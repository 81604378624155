
.section{
    background: radial-gradient(50% 50% at 50% 50%, #292C35 0%, #17181D 100%);
 }
 
 .main{
    height: 100vh;
    background-image: url(../../../public/images/Frame.svg);
    background-repeat: no-repeat;
    background-size: cover;
    
 }